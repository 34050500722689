import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SOCIAL_NETWORKS_VALUES } from '@shared/constants/communities.constants';
import { REFERRED_PROGRAM_VALUES_RE } from '@shared/constants/recommend.constants';
import { SocialNetwork } from '@shared/interfaces/frontend/communities';

@Component({
  selector: 'milab-card-social',
  templateUrl: './card-social.component.html',
  styleUrls: ['./card-social.component.scss']
})
export class CardSocialComponent implements OnInit {
  
  @Input() socialNetworks: SocialNetwork[] = [];
  @Input() referredCode: string = '';
  isMobile: boolean = false;
  shareLink = '';
  whatsappText = '%C2%A1Hola!%20Realic%C3%A9%20mi%20solicitud%20de%20pr%C3%A9stamo%20en%20Mibanco%20Lab%20gracias%20a%20tu%20recomendaci%C3%B3n%20%F0%9F%99%8C.%0A%F0%9F%93%9D%20Ingresa%20tus%20datos%20en%20este%20enlace%20para%20que%20te%20puedan%20premiar%20en%20caso%20mi%20desembolso%20sea%20exitoso%20%F0%9F%93%B2%3A ';
  constructor() { }

  ngOnInit(): void {
    this.shareLinkOnInit(window.location.origin);
    this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkIfMobile();
  }

  shareLinkOnInit(baseUrl: string): void {
    this.shareLink = `${baseUrl}${REFERRED_PROGRAM_VALUES_RE.registerRoute}${this.referredCode}`
}

  checkIfMobile() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
  }

  validResize(item: any): boolean {
    return (this.isMobile && item.mobile) || (!this.isMobile && item.desktop);
  }

  shareSocialNetworks(name: string) {
    navigator.clipboard.writeText(this.shareLink).then();
    switch (name) {
      case SOCIAL_NETWORKS_VALUES.whatsApp: {
        const urlWsp = this.isMobile ? REFERRED_PROGRAM_VALUES_RE.urlShareWhatsApp : REFERRED_PROGRAM_VALUES_RE.urlShareWhatsAppWeb;
        const urlWhatsapp = `${urlWsp}${this.whatsappText}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlWhatsapp);
        break;
      }
      case SOCIAL_NETWORKS_VALUES.facebook: {
        const urlFacebook = `${REFERRED_PROGRAM_VALUES_RE.urlShareFacebook}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlFacebook);
        break;
      }
      case SOCIAL_NETWORKS_VALUES.sms: {
        const urlSms = `${REFERRED_PROGRAM_VALUES_RE.urlShareSms}${this.whatsappText}${encodeURIComponent(this.shareLink)}`;
        this.redirectAndShare(urlSms);
        break;
      }
    }
  }

  redirectAndShare(url: string): void {
    window.open(url, '_blank');
  }

  handleKeyPress(event: any) {
    //noinspection
  }
}
