import { Component} from '@angular/core';

@Component({
  selector: 'milab-page-points-program',
  templateUrl: './page-points-program.component.html',
  styleUrls: ['./page-points-program.component.scss']
})
export class PagePointsProgramComponent {

}
