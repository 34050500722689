import { Component, ElementRef, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DOCUMENT } from '@angular/common';
import { MESSAGE_TYPES, messageTypes } from '@shared/constants/financing.constants';
import { DOCUMENTTYPELIST, DOCUMENTTYPELIST_DNI_ID } from '@shared/data/login/login.json';
import { IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';
import { Login } from '@shared/models/login.model';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { FinancingService } from '@shared/services/financing.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SeedCashService } from '@shared/services/seed-cash.service';
import { UserInteractionLogService } from '@shared/services/user-interaction-log.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { EmptyError, of } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { ModalGeneralMessageComponent } from './modal-general-message/modal-general-message.component';
import { RecommendService } from '@shared/services/recommend.service';

@Component({
  selector: 'yevo-general-message',
  templateUrl: './general-message.component.html',
  styleUrls: ['./general-message.component.scss'],
})
export class GeneralMessageComponent implements OnInit {
  @Input() isLogged: boolean = false;
  @Input() unsuccess!: any;
  @Input() messageInformation!: any;
  @Input() showRegister: boolean = false;
  @Input() isAffiliate: boolean = false;
  @Input() pageType: string = '';
  @Input() messageType: messageTypes = 'not-sent';
  @Input() ip!: any;
  @Input() username: string = '';
  @Input() documentNumber: string = '';
  @Input() email: string = '';
  @Input() typeForm: string = '';
  @Input() isFinanceRejectRequest: boolean = false;

  typePage: string = '';
  afilliate: string = 'Envío NA';

  name: string = '';
  showPassword: boolean = false;
  typeDocument: string = this.financingService.assessEligibilityExternalRequest?.typeDocument;

  showErrorMessage = false;
  phone!: string;
  estado: string = '';
  documentTypeList: IDropdownItem[] = DOCUMENTTYPELIST;
  documentTypeSelected: IDropdownItem = this.documentTypeList[0];

  loginForm!: FormGroup;
  loginError?: string | null;
  closeResult = '';

  isSeedCash: boolean = false;
  validateEmail: boolean = false;
  externalMessage: string = '¡Disfruta todos los beneficios que tenemos para ti y tu negocio!';

  // DIRECT LOGIN (ACCOUNT FORM)
  isVersion2: boolean = false;
  hideLoginForm: boolean = false;
  private _chrome = navigator.userAgent.indexOf('Chrome') > -1;
  aliado = '';
  isYape: boolean = false;
  breadcrumbs: any = [
    { pageName: 'Financiamiento', url: ['/financiamiento', 'solicitud'], active: true },
    { pageName: 'Financiamiento', url: null, active: false },
  ];

  breadUrl: string = '/financiamiento/solicitud';
  user!: any;
  codeRecomended!: string;
  activeModal: boolean = false;
  referredCode: string='';

  constructor(
    private dataLayerService: DataLayerService,
    private router: Router,
    private financingService: FinancingService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private seedCashService: SeedCashService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private userInteractionLogService: UserInteractionLogService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private localStorageService: LocalStorageService,
    private recommendService:RecommendService,
    private _el: ElementRef,
    @Inject(DOCUMENT) private _document: any
  ) {
    const userProfile = this.localStorageService.get('userProfile');
    if (userProfile) {
      this.user = JSON.parse(userProfile);
    }
  }

  ngOnInit(): void {
    this.codeRecomended =this.financingService.solicitorCode;
    this.referredCode = this.financingService.defaultRefersData.referredCode;
    this.getUsername();
    this.showBreadcrumbsForm();
    this.typeOfPage();
    this.checkAffiliate();
    this.removeAutocomplete();
    this.validateStep();
    this.getParams();
    this.validateUrlLogged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isAffiliate?.currentValue) {
      this.documentTypeSelected = this.documentTypeList.filter((item) => item.value === this.typeDocument)[0];
      this.buildLoginForm();
      this.loginForm.controls['documentNumber'].setValue(this.documentNumber);
      this.validateDirectLogin();
    } else if (
      changes?.showRegister.currentValue &&
      (changes.typeForm.currentValue === 'YAPE' || changes.typeForm.currentValue === 'yape')
    ) {
      this.isFormYapeRegister();
    }
  }

  validateUrlLogged() {
    if (this.isLogged) {
      this.afilliate = 'Envío A';
    }
    this._document.getElementById('formAmountBreadcrumbs').style.display = 'none';
    if (this.isLogged) {
      this.breadUrl = '/nuevo-intranet/dashboard';
    }
  }

  showBreadcrumbsForm() {
    if (this.typeForm === 'general') {
      this.breadCrumbsChange({ pageName: 'Financiamiento General', url: null, active: false });
      this.sendTrackingRecomended();
    }
    if (this.typeForm === 'aprobadodigital') {
      this.breadCrumbsChange({ pageName: 'Aprobado digital', url: null, active: false });
    }
    if (this.typeForm === 'preaprobadodigital') {
      this.breadCrumbsChange({ pageName: 'Pre-aprobado digital', url: null, active: false });
    }
    if (this.typeForm === 'cde') {
      this.breadCrumbsChange({ pageName: 'Compra deuda express', url: null, active: false });
    }
    if (this.typeForm === 'yape') {
      this.breadCrumbsChange({ pageName: 'Financiamiento Mibanco', url: null, active: false });
    }
    if (this.typeForm === 'semilla') {
      this.breadCrumbsChange({ pageName: 'Mi Efectivo Semilla', url: null, active: false });
    }
  }

  breadCrumbsChange(page: any) {
    let bread = { pageName: 'Financiamiento', url: null, active: true };
    if (this.isLogged) {
      bread = { pageName: 'Financiamiento', url: null, active: true };
    }
    this.breadcrumbs = [bread, page];
  }

  typeOfPage() {
    if (this.messageType === 'sent') {
      this.typePage = 'Solicitud Exitosa';
      return;
    }
    if (this.messageType === 'already-sent') {
      this.typePage = 'Ya tienes una solicitud';
      return;
    }

    if (this.messageType === undefined) {
      this.typePage = 'No se puede gestionar';
      return;
    }

    this.typePage = 'No se puede gestionar';
  }

  removeAutocomplete() {
    if (this._chrome) {
      if (this._el.nativeElement.getAttribute('autocomplete') === 'off') {
        setTimeout(() => {
          this._el.nativeElement.setAttribute('autocomplete', 'off');
        });
      }
    }
  }

  isFormYapeRegister() {
    this.documentTypeSelected = this.documentTypeList.filter((item) => item.value === this.typeDocument)[0];
    this.financingService.isExternalFinancing = false;
    this.showErrorMessage = false;
    this.validateEmail = false;
    this.email = sessionStorage.getItem('emailYape') ?? '';
    this.isYape = true;

    this.breadcrumbs = [
      { pageName: 'Financiamiento', url: ['/financiamiento', 'solicitud'], active: true },
      { pageName: 'Financiamiento Mibanco', url: null, active: false },
    ];
  }

  private getUsername(): void {
    this.name = this.financingService.sucessFinancingSolicitudeRegister?.name;
    if (this.isLogged) {
      this.username = this.name || this.user.name || 'Hola';
    } else if (this.username === null || this.username === undefined || this.username === '') {
      this.financingService.isExternalFinancing = true;
      this.username = this.financingService.sucessFinancingSolicitudeRegister?.name || 'Hola';
    }
  }

  public findAgencies(): void {
    this.pushMarks('Ver agencias cercanas', '(not available)');
    window.open('https://www.mibanco.com.pe/ubicanos', '_blank', 'noopener noreferrer');
  }

  public pushMarks(option: string, state: string): void {
    this.dataLayerService.pushThanksPageEventGeneral(this.typePage, this.afilliate, option, state);
  }

  public goToHome(): void {
    this.pushMarks('Ir al inicio', '(not available)');
    if (this.isLogged) {
      this.router.navigate(['/nuevo-intranet', 'dashboard'], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/'], { queryParamsHandling: 'merge' });
    }
  }

  public getLoginCaptcha() {
    this.recaptchaV3Service.execute('register').subscribe((token: string = '') => {
      this.loginForm.get('recaptcha')?.setValue(token);
      this.login();
    });
  }

  private validateStep(): void {
    this.isSeedCash = false;

    if (this.seedCashService?.leadData?.txtCorreo && this.messageType == MESSAGE_TYPES.SENT_MESSAGE) {
      this.changeSeedCashMessage();
      this.isSeedCash = true;
    }

    if (this.financingService.assessEligibilityExternalRequest) {
      this.email = this.financingService.assessEligibilityExternalRequest?.email;
      this.documentNumber = this.financingService.assessEligibilityExternalRequest?.documentNumber;
      this.typeDocument = this.financingService.assessEligibilityExternalRequest?.typeDocument;
      this.phone = this.financingService.assessEligibilityExternalRequest?.phone;
    } else if (this.seedCashService.leadData) {
      this.email = this.seedCashService.leadDataInvalidEligibility?.email ?? this.seedCashService.leadData?.txtCorreo;
      this.documentNumber =
        this.seedCashService.leadDataInvalidEligibility?.documentNumber ?? this.seedCashService.leadData?.txtDocumento;
      this.typeDocument = DOCUMENTTYPELIST_DNI_ID;
      this.phone = this.seedCashService.leadDataInvalidEligibility?.phone ?? this.seedCashService.leadData?.txtCelular;
    }

    if (
      this.email &&
      this.documentNumber &&
      this.typeDocument &&
      this.phone &&
      this.financingService.isExternalFinancing
    ) {
      this.financingService.isExternalFinancing = false;
    }
  }

  private changeSeedCashMessage() {
    this.messageInformation.name = '@name,';
    this.messageInformation.title = 'tu solicitud de préstamo ha sido enviada.';
    this.messageInformation.name = this.messageInformation.name.replace('@name', (this.username || this.name).trim());
    this.messageInformation.subtitle =
      'Te contactaremos vía llamada o correo para continuar con la evaluación.';
  }

  /** LOGIN METHODS */
  checkAffiliate() {
    if (this.documentNumber) {
      this.authService
        .checkDocument(this.documentNumber)
        .pipe(take(1))
        .subscribe(
          (resp) => {
            this.isAffiliate = true;
            this.afilliate = 'Envío A';
            this.documentTypeSelected = this.documentTypeList.filter((item) => item.value === this.typeDocument)[0];
            this.buildLoginForm();
            this.loginForm.controls['documentNumber'].setValue(this.documentNumber);
            this.validateDirectLogin();
          },
          (error) => {
            this.isAffiliate = false;
            this.afilliate = 'Envío NA';
          }
        );
    }
  }

  /** UTILS */
  public getDocumentTypeEvent(event: ISelectItem): void {
    this.documentTypeSelected = event.item!;
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      documentNumber: [this.documentNumber, [Validators.required, Validators.pattern('[0-9]{8}')]],
      password: [null, [Validators.required, Validators.maxLength(30)]],
      rememberMe: [true],
      recaptcha: [null],
    });
  }

  private get loginInfo(): Login {
    return this.loginForm.value as Login;
  }

  login(): void {
    this.loginError = null;
    this.authService
      .login(this.loginInfo, this.ip)
      .pipe(concatMap((response) => (response ? this.authService.getUserProfile() : of(EmptyError))))
      .subscribe(
        (response) => {
          if (response) {
            this.pushMarks('Ingresar ahora', 'Inicio exitoso');
            this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
              this.userInteractionLogService.logUserAction(user?.documentNumber!, 'click', 'botón de logeo', 'Login');
            });
            this.router.navigate(['/nuevo-intranet', 'dashboard'], { queryParamsHandling: 'merge' });
          }
        },
        (err) => {
          this.pushMarks('Ingresar ahora', 'Intención de inicio');
          if (err?.error?.code === 'userNotActivated') {
            const validateActivate = err.error.message;
            const validateNavigation = validateActivate.split('-');
            if (validateNavigation[2] === 'sms') {
              this.router.navigate(['verificacion-de-activacion/sms'], {
                queryParams: { email: err.error.message },
                queryParamsHandling: 'merge',
              });
            } else {
              this.router.navigate(['verificacion-de-activacion'], {
                queryParams: { email: err.error.message },
                queryParamsHandling: 'merge',
              });
            }
          } else {
            this.loginError = err.error.message;
          }
        }
      );
  }

  public open() {
    this.modalService
      .open(ModalGeneralMessageComponent, { ariaLabelledBy: 'modal-term', size: 'md', centered: true })
      .result.then();
  }

  /** DIRECT LOGIN */
  private validateDirectLogin() {
    this.route.queryParams.subscribe((params) => {
      this.isVersion2 = params.version == 2;
      this.hideLoginForm = this.isVersion2 && !!this.seedCashService?.accountEmail;
      this.loginForm.get('password')?.setValue(this.seedCashService?.accountPass!);
    });
  }

  private getParams() {
    this.route.queryParams.subscribe((params) => {
      this.aliado = params.aliado || '';
    });
  }

  public showHidePassword(event: any): void {
    this.showPassword = event;
  }

  public goToRegister(): void {
    if (this.isLogged) {
      this.router.navigate(['/nuevo-intranet', 'dashboard'], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate(['/registro', 'paso-1'], { queryParamsHandling: 'merge' });
    }
  }
  
  sendTrackingRecomended() {
    if (this.financingService.hasSentTrackingRecommended) {
      return;
    }
  
    this.recommendService.sendTrackingRecommended(this.financingService.trackingRecommended).pipe(take(1))
      .subscribe(
        (response) => {
          this.financingService.solicitorCode = response.solicitorCode;
          this.codeRecomended =this.financingService.solicitorCode;
          this.financingService.hasSentTrackingRecommended = true;
        },
        (error) => {
          console.error('error', error);
        }
      );
  }

  showEmitter(event: boolean) {
    this.activeModal = event;
  }
}
