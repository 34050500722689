import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'milab-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component implements OnInit {
  @Input() activedIcon: boolean = false;
  @Input() titleButon!:string;
  @Input() urlAction:string = '/';
  @Input() isExternal: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    //Do nothing
  }

  goHome(){
    if(this.isExternal){
      window.open(this.urlAction, '_blank');
    }else{
      this.router.navigate([this.urlAction]);
    }
  }
}