import { Component, Input, OnInit } from '@angular/core';
import { REFERRED_PROGRAM_VALUES_RE } from '@shared/constants/recommend.constants';

@Component({
  selector: 'milab-box-link',
  templateUrl: './box-link.component.html',
  styleUrls: ['./box-link.component.scss']
})
export class BoxLinkComponent implements OnInit {
  @Input() referredCode: string='991234567899';
  maxTitleLengthShareText: number = 22;
  inputIsDisabled: boolean = true;
  shareLink = '';
  baseUrl = '';
  selectedCopyLink: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.shareLinkOnInit(window.location.origin);
  }

  copyLink() {
    navigator.clipboard
    .writeText(this.shareLink)
    .then(() => {
      this.selectedCopyLink = true;
      setTimeout(() => {
        this.selectedCopyLink = false;
      }, 2000);
    })
    .catch((err) => { });
  }

  shareLinkOnInit(baseUrl: string): void {
      this.shareLink = `${baseUrl}${REFERRED_PROGRAM_VALUES_RE.registerRoute}${this.referredCode}`
  }

  truncateTextRecommend(text: string, maxLength: number): string {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  }
  
  handleKeyDown(event: KeyboardEvent): void {
    //Do something
  }
}
